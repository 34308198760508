import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`Overview`}</strong></h2>
    <p>{`Vault governance is a set of capabilities that enable customers to govern and control access to sensitive data that reside in Skyflow Vaults. The Skyflow Vault governance model enforces granular, real-time decisions on every data access request with minimum latency. At the heart of this capability is a Policy Based Access Control (PBAC) model that inherits the best of two well-proven access control models - Role Based Access Control (RBAC) and Attribute Based Access Control (ABAC). `}</p>
    <p>{`We have also developed an english-like policy expression language and an accompanying policy code editor that even non-technical users can use to author complex and condition based access rules. All of this capability is surfaced up in the product UI through two tabs - Governance and Settings which are accessible to all Vault Owners. `}</p>
    <p>{`We also have support for policy authoring and role assignment and much more via our Policy and Role APIs.  `}</p>
    <h3 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h3>
    <h4 {...{
      "id": "secure-multi-party-data-sharing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#secure-multi-party-data-sharing",
        "aria-label": "secure multi party data sharing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Secure Multi-Party Data Sharing`}</h4>
    <p>{`Skyflow enables the "isolate, protect and harness" approach to manage sensitive data. In order to harness the value of this data, applications often need to communicate with each other and share data. When it comes to sharing sensitive data - your organization’s crown jewels, it becomes even more important to ensure responsible stewardship of this data. This responsibility hinges on the following capabilities: `}</p>
    <h5 {...{
      "id": "privacy-preserving-data-sharing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#privacy-preserving-data-sharing",
        "aria-label": "privacy preserving data sharing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Privacy preserving data sharing`}</h5>
    <p>{`Privacy preservation is one of the key value propositions of Skyflow. By employing data loss prevention techniques such as tokenization, redaction, masking and encrypted computing, we can ensure that the same data is shared differentially based on the identity of the end consumer and the specific business use case. `}</p>
    <h6 {...{
      "id": "example-use-case",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#example-use-case",
        "aria-label": "example use case permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Use Case:`}</h6>
    <ul>
      <li parentName="ul">{`A credit card applicant seeing her own PII including SSN in plain text as opposed to a customer support agent seeing only the last 4 digits of her SSN (Masked) for identity verification purposes. `}</li>
      <li parentName="ul">{`A front-office staffer leveraging the power of encrypted computation to match the SSN number of a customer without ever viewing the entire SSN column in plain text.`}</li>
    </ul>
    <h5 {...{
      "id": "consent-based-data-sharing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#consent-based-data-sharing",
        "aria-label": "consent based data sharing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Consent-based data sharing`}</h5>
    <p>{`Enforcing consent before sharing data is paramount to build customer trust. Customers trust your organization with their PII and they deserve to know and control how that data would be used and processed. By enforcing row-level data access control, Skyflow can check the consent field value for each individual before sharing any PII data with third parties.`}</p>
    <h6 {...{
      "id": "example-use-case-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#example-use-case-1",
        "aria-label": "example use case 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Use Case:`}</h6>
    <ul>
      <li parentName="ul">{`Sharing COVID-19 test results and PII of patients with state officials for contact tracing purposes, only if the patient has given consent to share their data.  `}</li>
    </ul>
    <h5 {...{
      "id": "data-minimization-with-granular-access-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#data-minimization-with-granular-access-control",
        "aria-label": "data minimization with granular access control permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Data Minimization with granular access control`}</h5>
    <p>{`Enforcing principles of least privilege is an effective way to reduce your attack surface area when governing access to sensitive data. User accounts and apps should only have access to the specific fields that they need to perform a legitimate business function. Using column-level and row-level access control, support for SQL WHERE clauses and Common Expression Language, Skyflow enforces very granular and condition based access policies. `}</p>
    <h6 {...{
      "id": "example-use-case-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#example-use-case-2",
        "aria-label": "example use case 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Use Case:`}</h6>
    <ul>
      <li parentName="ul">{`A physician can view and edit medication information of only those patients she treats and not the entire patient database.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      